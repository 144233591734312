import React from 'react';
import { motion } from 'framer-motion';
import { 
  FaShieldAlt, 
  FaExclamationTriangle,
  FaUserSecret,
  FaGavel,
  FaCarCrash,
  FaSkull,
  FaUserSlash,
  FaMapMarkedAlt,
  FaBalanceScale,
  FaHandPaper
} from 'react-icons/fa';
import { useTheme } from '../context/ThemeContext';

export default function Rules() {
  const { theme } = useTheme();
  const isDark = theme === 'dark';

  const rules = [
    {
      icon: <FaShieldAlt className="w-8 h-8" />,
      title: "La Règle d'Or",
      description: "Le fondement de notre communauté",
      content: `MOONLIGHT RP est avant tout un jeu, et son objectif principal est de s'amuser dans une expérience immersive et respectueuse pour tous. Il est essentiel de maîtriser tout comportement de colère HRP. Nous sommes ici pour jouer ensemble et partager des moments agréables.`,
      subrules: [
        {
          subtitle: "1. Obligation de terminer les scènes RP",
          text: "Il est impératif de jouer et de terminer toutes les scènes RP, quelle que soit leur qualité. Le RP ne se limite pas à la technicité ou à la perfection des actions, mais repose sur la cohérence et l'immersion."
        },
        {
          subtitle: "2. Respect et contribution à l'expérience globale",
          text: "Chaque joueur contribue à l'expérience collective. Il est donc primordial de maintenir un environnement respectueux et agréable pour tous les participants."
        }
      ]
    },
    {
      icon: <FaSkull className="w-8 h-8" />,
      title: "Règles essentielles",
      description: "Directives essentielles pour les interactions",
      items: [
        {
          title: "LE DM (DEATHMATCH)",
          content: "Il est formellement interdit de tuer un joueur sans une raison RP légitime. Chaque meurtre doit être justifié par un motif RP solide et cohérent avec le personnage que vous incarnez."
        },
        {
          title: "LE POWERGAME",
          content: "Le powergame désigne le fait de faire agir son personnage de manière irréaliste ou incohérente avec ses capacités. Les pratiques telles que le bunny hop ou le chicken run sont strictement interdites."
        },
        {
          title: "LE METAGAME",
          content: "Le metagame consiste à exploiter des informations obtenues hors RP dans une situation RP, ce qui compromet l'équité et l'immersion."
        }
      ]
    },
    {
      icon: <FaCarCrash className="w-8 h-8" />,
      title: "Interactions Véhicules",
      description: "Règles concernant l'utilisation des véhicules",
      items: [
        {
          title: "LE CARKILL",
          content: "Tuer un joueur avec un véhicule est strictement interdit, sauf si cela fait partie intégrante d'une scène RP cohérente et justifiée."
        },
        {
          title: "LE CARJACK",
          content: "Le Carjack est autorisé mais doit être effectué avec un minimum de roleplay. À défaut, cela sera considéré comme du powergame."
        }
      ]
    },
    {
      icon: <FaHandPaper className="w-8 h-8" />,
      title: "Racket et Interactions",
      description: "Règles pour les activités criminelles",
      content: `Il est interdit d'appeler un joueur via son métier (job) pour ensuite le racketter. Les rackets doivent être réalisés de manière discrète, réaliste et cohérente.`,
      rules: [
        "Un seul racket par jour par joueur",
        "Limite de 50% du butin de la victime",
        "Interdiction de kidnapper pour racketter",
        "Interdiction de forcer un retrait d'argent",
        "Interdiction de tuer après coopération"
      ]
    },
    {
      icon: <FaMapMarkedAlt className="w-8 h-8" />,
      title: "Safe Zones",
      description: "Safe Zones et Lieux Sensibles",
      items: [
        "Lieux de travail (jobs à farm)",
        "Institutions publiques",
        "Parking central",
        "Banques et guichets automatiques",
        "Zones sous haute surveillance"
      ]
    },
    {
      icon: <FaBalanceScale className="w-8 h-8" />,
      title: "Litiges et Sanctions",
      description: "Gestion des conflits",
      content: `Les litiges HRP doivent être réglés en dehors du jeu, sur Discord. Il est impératif de ne pas interrompre une scène RP pour discuter en HRP.`,
      guidelines: [
        "Utilisation recommandée de captures vidéo/écran",
        "Traitement basé sur des preuves solides",
        "Possibilité de contacter via ticket Discord",
        "Décisions équitables par les administrateurs",
        "Sanctions adaptées selon la gravité"
      ]
    }
  ];

  return (
    <div className="min-h-screen pt-24 px-4 pb-12">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-5xl font-bold mb-4 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-600 bg-clip-text text-transparent">
            Règlement du Serveur
          </h1>
          <p className={`text-lg max-w-2xl mx-auto ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
            Avant de commencer, nous vous invitons à prendre connaissance des règles qui suivent
          </p>
        </motion.div>

        <div className="space-y-8">
          {rules.map((category, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`${
                isDark 
                  ? 'bg-gray-800/50 border-gray-700' 
                  : 'bg-white border-gray-200'
              } rounded-2xl border overflow-hidden shadow-lg`}
            >
              <div className="p-8">
                <div className="flex items-start gap-6 mb-8">
                  <div className={`p-4 rounded-xl ${
                    isDark ? 'bg-purple-500/10' : 'bg-purple-100'
                  } text-purple-500`}>
                    {category.icon}
                  </div>
                  <div>
                    <h2 className={`text-2xl font-bold mb-2 ${
                      isDark ? 'text-purple-400' : 'text-purple-600'
                    }`}>
                      {category.title}
                    </h2>
                    <p className={`${
                      isDark ? 'text-gray-400' : 'text-gray-600'
                    }`}>
                      {category.description}
                    </p>
                  </div>
                </div>

                {category.content && (
                  <div className={`mb-6 p-4 rounded-xl ${
                    isDark ? 'bg-gray-900/50' : 'bg-gray-50'
                  }`}>
                    <p className={`${isDark ? 'text-gray-300' : 'text-gray-700'}`}>
                      {category.content}
                    </p>
                  </div>
                )}

                {category.subrules && (
                  <div className="space-y-6">
                    {category.subrules.map((subrule, idx) => (
                      <div key={idx} className={`p-4 rounded-xl ${
                        isDark ? 'bg-gray-900/50' : 'bg-gray-50'
                      }`}>
                        <h3 className={`font-semibold mb-2 ${
                          isDark ? 'text-purple-400' : 'text-purple-600'
                        }`}>
                          {subrule.subtitle}
                        </h3>
                        <p className={`${isDark ? 'text-gray-300' : 'text-gray-700'}`}>
                          {subrule.text}
                        </p>
                      </div>
                    ))}
                  </div>
                )}

                {category.items && (
                  <div className="space-y-4">
                    {category.items.map((item, itemIndex) => (
                      <motion.div
                        key={itemIndex}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.1 + itemIndex * 0.05 }}
                        className={`p-4 rounded-xl ${
                          isDark 
                            ? 'bg-gray-900/50' 
                            : 'bg-gray-50'
                        }`}
                      >
                        {typeof item === 'string' ? (
                          <div className="flex items-center gap-3">
                            <div className={`w-2 h-2 rounded-full ${
                              isDark ? 'bg-purple-500' : 'bg-purple-400'
                            }`} />
                            <p className={`${
                              isDark ? 'text-gray-300' : 'text-gray-700'
                            }`}>
                              {item}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <h3 className={`font-semibold mb-2 ${
                              isDark ? 'text-purple-400' : 'text-purple-600'
                            }`}>
                              {item.title}
                            </h3>
                            <p className={`${
                              isDark ? 'text-gray-300' : 'text-gray-700'
                            }`}>
                              {item.content}
                            </p>
                          </div>
                        )}
                      </motion.div>
                    ))}
                  </div>
                )}

                {category.rules && (
                  <div className="space-y-3 mt-4">
                    {category.rules.map((rule, ruleIndex) => (
                      <div key={ruleIndex} className="flex items-center gap-3">
                        <div className={`w-2 h-2 rounded-full ${
                          isDark ? 'bg-purple-500' : 'bg-purple-400'
                        }`} />
                        <p className={`${
                          isDark ? 'text-gray-300' : 'text-gray-700'
                        }`}>
                          {rule}
                        </p>
                      </div>
                    ))}
                  </div>
                )}

                {category.guidelines && (
                  <div className="space-y-3 mt-4">
                    {category.guidelines.map((guideline, guidelineIndex) => (
                      <div key={guidelineIndex} className="flex items-center gap-3">
                        <div className={`w-2 h-2 rounded-full ${
                          isDark ? 'bg-purple-500' : 'bg-purple-400'
                        }`} />
                        <p className={`${
                          isDark ? 'text-gray-300' : 'text-gray-700'
                        }`}>
                          {guideline}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
          className={`mt-12 p-8 rounded-xl ${
            isDark 
              ? 'bg-red-500/10 border-red-500/20' 
              : 'bg-red-50 border-red-100'
          } border`}
        >
          <div className="flex items-start gap-6">
            <FaExclamationTriangle className={`w-8 h-8 ${
              isDark ? 'text-red-400' : 'text-red-500'
            } flex-shrink-0 mt-1`} />
            <div>
              <h3 className={`text-xl font-bold mb-2 ${
                isDark ? 'text-red-400' : 'text-red-500'
              }`}>
                Important
              </h3>
              <p className={`${isDark ? 'text-gray-300' : 'text-gray-700'} mb-4`}>
                Toute transgression des règles énoncées dans ce règlement entraînera des sanctions, 
                qui pourront aller de simples avertissements à des suspensions temporaires ou permanentes, 
                en fonction de la gravité de l'infraction.
              </p>
              <p className={`${isDark ? 'text-gray-400' : 'text-gray-600'} text-sm`}>
                L'objectif est de garantir une expérience de jeu respectueuse, agréable et cohérente 
                pour tous les joueurs. En cas de doute, nous vous encourageons à consulter ce règlement 
                régulièrement et à toujours privilégier le bon sens et le fair-play.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}